import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'
import 'components/navibar/style.scss'
import Logo from '../../../content/images/logo.png'
import MobileDropdown from 'components/navibar/navibar-mobile'
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";

interface Props {
  title: string
  location: Location
}

const Navibar: React.FC<Props> = ({ location, title }: Props) => {

  const [scrolled, setScrolled] = useState("navbar navbar-expand navbar-light flex-column flex-md-row");


  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20;
      if (isScrolled) {
        setScrolled("navbar navbar-expand navbar-light flex-column flex-md-row fixed");
      } else {
        setScrolled("navbar navbar-expand navbar-light flex-column flex-md-row");
      }
    };

    document.addEventListener('scroll', handleScroll, { passive: "navbar navbar-expand navbar-light flex-column flex-md-row fixed" });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  return (
    <>
      <div id="pre-header">
        <div className="container" id="pre-header-container">
          <div className="row justify-content-end">
            <div className="col-lg-12 text-right">
              <Link to="/referring-doctors/referral-form"><button className="nav-btn-2">Referral Form</button></Link>
              <a href="tel:3178464980"><button className="nav-btn-2 tel-btn"><FaPhoneAlt className="mr-2" size="18"/>317-846-4980</button></a>
            </div>
          </div>
        </div>
      </div>
      <nav className={scrolled} id="main-navigation">
        <div className="container" id="navibar-container">
          <div className="hidden-md-up">
            <MobileDropdown />
          </div>
          <div className="col-lg-2">
            <Link className="text-center" to="/">
              <img src={Logo} id="logo" alt="endodontics logo"/>
            </Link>
          </div>
          <div className="col-lg-10">

            <div className="navbar-nav-scroll hidden-md-down">
              <ul className="navbar-nav bd-navbar-nav justify-content-end">
                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active main'
                      : 'nav-item main'
                  }
                >
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active nav-dropdown main'
                      : 'nav-item nav-dropdown  main'
                  }
                >
                  <Link to="" className="nav-link" aria-current="page">
                    Patient Information
                  </Link>
                  <ul className="nav-submenu">
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/patient-information/introduction" className="nav-link">
                        Introduction
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/patient-information/first-visit" className="nav-link">
                        First Visit
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/patient-information/scheduling" className="nav-link">
                        Scheduling
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/patient-information/financial-policy" className="nav-link">
                        Financial Policy
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/patient-information/insurance" className="nav-link">
                        Insurance
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/patient-information/patient-registration" className="nav-link">
                        Patient Registration
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/patient-information/endodontic-faq" className="nav-link">
                        Endodontic FAQ
                      </Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active nav-dropdown main'
                      : 'nav-item nav-dropdown  main'
                  }
                >
                  <Link to="" className="nav-link" aria-current="page">
                    Instructions
                  </Link>
                  <ul className="nav-submenu">
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/instructions/before-endodontic-treatment" className="nav-link">
                        Before Endodontic Treatment
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/instructions/general-instructions" className="nav-link">
                        General Instructions
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/instructions/after-treatment" className="nav-link">
                        After Treatment
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active nav-dropdown main'
                      : 'nav-item nav-dropdown main'
                  }
                >
                  <Link to="" className="nav-link" aria-current="page">
                    Procedures
                  </Link>
                  <ul className="nav-submenu">
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/procedures/root-canal-therapy" className="nav-link">
                        Root Canal Therapy
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/procedures/endodontic-retreatment" className="nav-link">
                        Endodontic Retreatment
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/procedures/apicoectomy" className="nav-link">
                        Apicoectomy
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/procedures/cracked-teeth" className="nav-link">
                        Cracked Teeth
                      </Link>
                    </li>
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/procedures/traumatic-injuries" className="nav-link">
                        Traumatic Injuries
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active nav-dropdown main'
                      : 'nav-item nav-dropdown  main'
                  }
                >
                  <Link to="" className="nav-link" aria-current="page">
                    Meet Us
                  </Link>
                  <ul className="nav-submenu">
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/meet-us/meet-the-doctor" className="nav-link">
                        Meet The Doctors
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active nav-dropdown main'
                      : 'nav-item nav-dropdown main'
                  }
                >
                  <Link to="" className="nav-link" aria-current="page">
                    Referring Doctors
                  </Link>
                  <ul className="nav-submenu">
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/referring-doctors/referral-form" className="nav-link">
                        Referral Form
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    location.pathname === '/'
                      ? 'nav-item active nav-dropdown main'
                      : 'nav-item nav-dropdown  main'
                  }
                >
                  <Link to="" className="nav-link" aria-current="page">
                    Contact Us
                  </Link>
                  <ul className="nav-submenu">
                    <li
                      className={
                        location.pathname === '/'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/contact-us/contact-information-office-map" className="nav-link">
                        Contact Information/Office Map
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className={
                    location.pathname === '/make-a-payment'
                      ? 'nav-item active main'
                      : 'nav-item main'
                  }
                >
                <a href ="https://payment.meridianendoindy.com/" target="_blank" className="nav-link">
                  Make A Payment
                </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
        </div>
      </nav>
      </>
  )
}

export default Navibar
