import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import { Link } from 'gatsby'

const Footer = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        bg: file(name: { eq: "bg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        logo: file(name: { eq: "logo" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1400) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        site {
          meta: siteMetadata {
            title
            description
            siteUrl
            author
            twitter
            adsense
          }
        },
      }
    `
  )
  const meta = data.site?.meta

  // Set ImageData.
  const bg = data.bg.childImageSharp.fluid
  const logo = data.logo.childImageSharp.fluid

  return (
    <div>
      <div id="footer-map">
        <ContainerFluid>
          <div className="map-frame">
            <iframe width="100%" height="300px" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://www.google.com/maps/embed?origin=mfe&pb=!1m6!3m3!1m2!2s9002+North+Meridian+Street,+Suite+201,+Indianapolis,+IN+46260!4s5662226363560454325!5e0!6i15"></iframe>
          </div>
        </ContainerFluid>
      </div>
      <div id="footer">
        <Container>
          <div className="col-lg-4 footer-logo-container">
            <Link to="/">
              <Img fluid={logo} className="footer-logo"/>
            </Link>
            <p>Meridian Endodontics provides progressive, and affordable healthcare, accessible on mobile and online for everyone.</p>
          </div>
          <div className="col-lg-4 footer-office-hours-container">
            <h4>Office Hours</h4>
            <div className="row">
              <div className="col-md-4">
                <p>Monday:</p>
                <p>Tuesday:</p>
                <p>Wednesday:</p>
                <p>Thursday:</p>
                <p>Friday:</p>
              </div>
              <div className="col-md-8">
                <p>8:00 AM - 5:00 PM</p>
                <p>8:00 AM - 5:00 PM</p>
                <p>8:00 AM - 5:00 PM</p>
                <p>8:00 AM - 5:00 PM</p>
                <p>8:00 AM - 5:00 PM</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 footer-information">
            <h4>Information</h4>
            <div className="row">
              <div className="col-md-3">
                <div className="footer-address">
                  <p>Address:</p>
                </div>
                <p>Phone:</p>
                <p>Fax:</p>
                <p>Email:</p>
              </div>
              <div className="col-md-9">
                <div className="footer-address">
                  <p>9002 North Meridian St, Suite 201 Indianapolis, IN 46260 </p>
                </div>
                <a href="tel:3178464980"><p className="footer-tel">317-846-4980</p></a>
                <a href="tel:3178464982"><p className="footer-tel">317-846-4982</p></a>
                <a href="mailto:office@meridianendoindy.com"><p>office@meridianendoindy.com</p></a>
              </div>
            </div>
          </div>
          <div className="col-lg-12 footer-links text-center">
            <ul className="list-inline">
              <li className="list-inline-item"><Link to="/patient-information/introduction">Patient Information</Link></li>
              <li className="list-inline-item"><Link to="/instructions/before-endodontic-treatment">Instructions</Link></li>
              <li className="list-inline-item"><Link to="/procedures/root-canal-therapy">Procedures</Link></li>
              <li className="list-inline-item"><Link to="/meet-us/meet-the-doctor">Meet Us</Link></li>
              <li className="list-inline-item"><Link to="/referring-doctors/referral-form">Referring Doctors</Link></li>
              <li className="list-inline-item"><Link to="/contact-us/contact-information-office-map">Contact Us</Link></li>
              <li className="list-inline-item"><a href="https://payment.meridianendoindy.com/" target="_blank">Make a Payment</a></li>
            </ul>
            <ul className="list-inline m-t-0">
              <li className="list-inline-item"><Link to="/disclaimer">Disclaimer</Link></li>
              <li className="list-inline-item"><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col-lg-12 text-center">
            <p className="footer-copyright copyright">© {meta.title} {new Date().getFullYear()}. All Rights Reserved. Web Design by <a href="https://adrienbird.com/" target="_blank" className="bird-digital">Bird Digital.</a></p>
          </div>
        </Container>
      </div>
    </div>

  )
}

const StyledBackgroundSection = styled(Footer)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
