import React, { Component } from 'react';
import { Link } from 'gatsby'
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaBars } from "@react-icons/all-files/fa/FaBars";

class MobileNavigation extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      hideMenu: true,
    };

    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true });
  }

  hideMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: false });
  }

  render() {
    return (
      <div>
        {
          this.state.showMenu
            ? (
              <button className="mobile-nav text-center" onClick={this.hideMenu}>
                <FaTimes size="21px" className="svg-icon-middle text-white"/>
              </button>
            )
            : (
              <button className="mobile-nav text-center" onClick={this.showMenu}>
                <FaBars size="21px" className="svg-icon-middle text-white"/>
              </button>
            )
        }
        {
          this.state.showMenu
            ? (
              <div
                className="menu mobile-menu"
                ref={(element) => {
                  this.dropdownMenu = element;
                }}
              >
                <div className="hidden-md-up">
                  <ul className="navbar-nav bd-navbar-nav justify-content-end">
                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active'
                        : 'nav-item'
                    }
                  >
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active nav-dropdown'
                        : 'nav-item nav-dropdown'
                    }
                  >
                    <Link to="" className="nav-link" >
                      Patient Information
                    </Link>
                    <ul className="nav-submenu">
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/patient-information/introduction" className="nav-link">
                          Introduction
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/patient-information/first-visit" className="nav-link">
                          First Visit
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/patient-information/scheduling" className="nav-link">
                          Scheduling
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/patient-information/financial-policy" className="nav-link">
                          Financial Policy
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/patient-information/insurance" className="nav-link">
                          Insurance
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/patient-information/patient-registration" className="nav-link">
                          Patient Registration
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/patient-information/endodontic-faq" className="nav-link">
                          Endodontic FAQ
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active nav-dropdown'
                        : 'nav-item nav-dropdown'
                    }
                  >
                    <Link to="" className="nav-link" >
                      Instructions
                    </Link>
                    <ul className="nav-submenu">
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/instructions/before-endodontic-treatment" className="nav-link">
                          Before Endodontic Treatment
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/instructions/general-instructions" className="nav-link">
                          General Instructions
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/instructions/after-treatment" className="nav-link">
                          After Treatment
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active nav-dropdown'
                        : 'nav-item nav-dropdown'
                    }
                  >
                    <Link to="" className="nav-link" >
                      Procedures
                    </Link>
                    <ul className="nav-submenu">
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/procedures/root-canal-therapy" className="nav-link">
                          Root Canal Therapy
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/procedures/endodontic-retreatment" className="nav-link">
                          Endodontic Retreatment
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/procedures/apicoectomy" className="nav-link">
                          Apicoectomy
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/procedures/cracked-teeth" className="nav-link">
                          Cracked Teeth
                        </Link>
                      </li>
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/procedures/traumatic-injuries" className="nav-link">
                          Traumatic Injuries
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active nav-dropdown'
                        : 'nav-item nav-dropdown'
                    }
                  >
                    <Link to="" className="nav-link" >
                      Meet Us
                    </Link>
                    <ul className="nav-submenu">
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/meet-us/meet-the-doctor" className="nav-link">
                          Meet The Doctors
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active nav-dropdown'
                        : 'nav-item nav-dropdown'
                    }
                  >
                    <Link to="" className="nav-link" >
                      Referring Doctors
                    </Link>
                    <ul className="nav-submenu">
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/referring-doctors/referral-form" className="nav-link">
                          Referral Form
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active nav-dropdown'
                        : 'nav-item nav-dropdown'
                    }
                  >
                    <Link to="" className="nav-link" >
                      Contact us
                    </Link>
                    <ul className="nav-submenu">
                      <li
                        className={
                          location.pathname === '/'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/contact-us/contact-information-office-map" className="nav-link">
                          Contact Information/Office Map
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li
                    className={
                      location.pathname === '/'
                        ? 'nav-item active'
                        : 'nav-item'
                    }
                  >
                    <a href="https://payment.meridianendoindy.com/" target="_blank" className="nav-link">
                      Make A Payment
                    </a>
                  </li>
                  </ul>
                </div>
              </div>
            )
            : (
              null
            )
        }
      </div>
    );
  }
}

export default MobileNavigation
