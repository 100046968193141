import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import emergence from 'emergence.js'
import Navibar from 'components/navibar/navibar'
import Footer from 'components/footer/footer'
import { siteMetadata } from '../../../gatsby-config'
import BackgroundImage from 'gatsby-background-image'
import 'modern-normalize/modern-normalize.css'
import 'prismjs/themes/prism.css'
import 'scss/gatstrap.scss'
import 'animate.css/animate.css'
import 'font-awesome/css/font-awesome.css'
import Img, { FixedObject } from 'gatsby-image'
import Waves from '/content/images/popup-waves.png'

interface Props {
  children?: React.ReactNode
  location: Location
}

const Layout: React.FC<Props> = ({ children, location }: Props) => {
  const data = useStaticQuery(
    graphql`
      query {
        popup_img: file(name: { eq: "popup-img" }) {
          childImageSharp {
            fluid(maxWidth: 463, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `
  )

  const popup_img = data.popup_img?.childImageSharp?.fluid
  const [popupStatus, setPopupStatus] = useState(false);
  const [popupNumber, setPopupNumber] = useState(1);
  const [popupReady, setPopupReady] = useState(false);
  const [modalCount,setModalCount] = useState(3);

  const decrease = () => {
   setModalCount(prevModalCount => {
     const newCount = Number(prevModalCount) - 1;
     localStorage.setItem("modalCount", newCount);
     return newCount;
   });
 };


  useEffect(
    () => {
      setTimeout(() => {
        setPopupReady(true);
      }, 5000);
      setTimeout(() => {
        setPopupStatus(true);
      }, 45000);
      const initialValue = localStorage.getItem("modalCount");
      if (initialValue) setModalCount(initialValue);
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );


{/*}  function checkModalNo() {
    if (setPopupNumber==1){
      decrease();
    }
  } */}
  function onExitIntent() {
    setPopupStatus(true);
    decrease();
  }

  function closeModal() {
    setPopupStatus(false);
    setPopupNumber(0);
  }

  useEffect(() => {
    emergence.init()
  })

  {/*console.log(localStorage.getItem("modalCount"));*/}
  {/*localStorage.removeItem("modalCount");*/}
  return (
    <div>
      { popupReady ? (
        <div className="exit-intent-trigger" onMouseOver={onExitIntent} ></div>
      ) : (
        <div className="exit-intent-trigger"></div>
      )
      }
      {popupStatus && popupNumber == 1 && popupReady && modalCount > 0  ? (
        <div className="modal show slide-bottom" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>&times;</button>
              </div>
                <div className="row row-1">
                <div className="col-lg-4 popup-image-container">
                  <Img fluid={popup_img} className="pop-up-img"/>
                </div>
                <div className="modal-body col-lg-8">
                  <h3 className="text-center">We give a healthier, more complete smile that can last a lifetime.</h3>
                  <h2 className="text-center">INVEST IN YOUR SMILE!</h2>
                  <form id="exit-form" method="POST" action="https://usebasin.com/f/f5c1f073ca48">
                    <div className="row">
                      <div className="col-md-6 pl-0 pr-2">
                        <input type="text" name="name"  placeholder="Name*" required/>
                      </div>
                      <div className="col-md-6 pr-0 pl-2">
                        <input type="tel" name="phone" placeholder="Number*" required/>
                      </div>
                    </div>
                    <input type="email" name="email" placeholder="Email*" required/>
                    <textarea name="message" placeholder="Message"></textarea>
                    <button type="submit">Send </button>
                  </form>
                  </div>
                </div>
                <img src={Waves} className="popup-waves"/>
              </div>
            </div>
          </div>
      ) : (
        null
      )
      }
      <Navibar title={siteMetadata.title} location={location} />
      {children}
      <Footer title={siteMetadata.title} author={siteMetadata.author} />
    </div>
  )
}

export default Layout
